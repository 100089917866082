@font-face {
  font-family: 'BrandonGrotesque-Black';
  src: local('BrandonGrotesque-Black'),
    url('./fonts/HVD Fonts - BrandonGrotesque-Black.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: local('BrandonGrotesque-Bold'),
    url('./fonts/HVD Fonts - BrandonGrotesque-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'BrandonGrotesque-BoldItalic';
  src: local('BrandonGrotesque-BoldItalic'),
    url('./fonts/HVD Fonts - BrandonGrotesque-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}
