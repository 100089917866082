.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}

.cells {
  min-width: 100px;
  max-width: 100px;
}

.noHay {
  color: rgb(173, 173, 173);
}

.siHay {
  font-weight: bold;
}

.dropdown-menu {
  max-height: 60vh;
  overflow-y: auto;
}

.botones{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}