.inputSelect{
 width: 45%;
}

.check{
    width: 15%;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
