.nombres {
  border-style: dotted;
  border-color: aquamarine;
  border-radius: 2px;
}

.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}

.cells {
  min-width: 100px;
  max-width: 100px;
}

.cellsChica {
  min-width: 70px;
  max-width: 70px;
}

.cellsGrande {
  min-width: 250px;
  max-width: 250px;
}

.cellsMasGrande {
  min-width: 750px;
  max-width: 750px;
}

.fila {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.columna {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.palomita {
  color: green;
}

.tache {
  color: red;
}