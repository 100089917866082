.nombres {
  border-style: dotted;
  border-color: aquamarine;
  border-radius: 2px;
}

.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}

.cells {
  min-width: 120px;
  max-width: 120px;
}

.cellsGrande {
  min-width: 250px;
  max-width: 250px;
}

.index {
  z-index: 2;
  width: 25vw;
}

.margin {
  margin: 10px;
}