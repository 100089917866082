.nombres {
  border-style: dotted;
  border-color: aquamarine;
  border-radius: 2px;
}

.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}

.cellsPermisos {
  min-width: 100px;
  max-width: 100px;
}

.cellsPermisosRuta {
  min-width: 400px;
  max-width: 400px;
}

.cellsPermisosAutorizar {
  min-width: 150px;
  max-width: 150px;
}

.cellsPermisosMediano {
  min-width: 200px;
  max-width: 200px;
}

.cellsPermisosGrande {
  min-width: 500px;
  max-width: 500px;
}

.buscaAlumnos {
  z-index: 1000;
  min-width: 20vw;
}

.buscaRutas {
  min-width: 140px;
  max-width: 140px;
  padding-inline: 10px;
}

.noHay {
  color: rgb(173, 173, 173);
}

.siHay {
  font-weight: bold;
}

.dropdown-menu {
  max-height: 60vh;
  overflow-y: auto;
}

.botones {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
}

.campus {
  color: red
}