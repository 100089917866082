.inputr{
width: 20vw;
}
.check{
    width: 15%;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fechas{
    display: flex;
    justify-content: space-evenly;
    max-width: 200px;
    margin: 5px;
    background-color: rgb(200, 240, 196);
}

.guardar{
    width: 400px;
}
