.nombres {
  border-style: dotted;
  border-color: aquamarine;
  border-radius: 2px;
}

.loading {
  margin: auto;
  width: 9%;
  padding: 20px;
}

.centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cells {
  min-width: 100px;
  max-width: 100px;
}

.cellsGrande {
  min-width: 200px;
  max-width: 200px;
}