div.sticky {
    position: sticky;
    top: 0;
    background-color: rgb(225, 235, 241);
    border: 2px solid rgb(97, 194, 235);
    border-radius: 8px;
    padding: 1vw;
    z-index: 1;
    opacity: 0.9;
}
.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    

}
.fila{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; 
    border-radius: 8px;
}

.head{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
}

.columna{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    border-radius: 8px;
}   

.botonImprimir {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: rgb(225, 235, 241);
    border: 2px solid rgb(97, 194, 235);
    border-radius: 8px;
}

.etiqueta {
    /* background-color: rgb(169, 220, 207); */
    border: 2px solid rgb(2, 11, 15);
    margin-left: 2vh;
    margin-right: 2vh;
    border-radius: 5px;
}

.titulos {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    padding: 0;
}

.division {
    width: 100%;
    height: 2vh;
}

.print {
    print-color-adjust: exact;
    page-break-inside: avoid;
}

@media print {
    .noprint {
        visibility: hidden;
        display: none;
    }
}

.excel-style-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.excel-style-table thead {
    background-color: #f4f4f4;
}

.excel-style-table th, .excel-style-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.excel-style-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.excel-style-table tr:hover {
    background-color: #f1f1f1;
}

.excel-style-table th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

.excel-style-table td {
    text-transform: capitalize;
}
